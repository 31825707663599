<template>
  <el-container>
    <el-header>
      <navtop />
    </el-header>
    <el-main class="main-center margin-auto-0">
      <router-view>
      </router-view>
    </el-main>
    <el-footer>
      <underneath :casenumber="casenumber"/>
    </el-footer>
  </el-container>
</template>

<script>
import api from './../service/api/api'
import underneath from '@/components/underneath/underneath'
import navtop from '@/components/navtop/navtop'
export default {
  name: "layout",
  components: {
    underneath,
    navtop
  },

  data() {
    return {
      casenumber:'',
    }
  },
  created() {
    this.casenumber = localStorage.getItem('casenumber')
  },
  methods: {



  }


}
</script>

<style scoped>
body {
  background-color: #FAFAFA;
}
.main-center {
  min-height: 1070px;
  background-color: #FAFAFA;
  width: 100%;
  padding: 0;

}
</style>