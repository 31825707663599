<template>
  <div class="layout-nav">
    <div class="nav-list dis-flex space-between margin-auto-0">
      <div class="nav-list-l dis-flex">
        <div @click="toIndex" class="nav-list-ls">
          <img src="../../assets/images/login.png" alt="" />
        </div>
        <ul class="head-lists dis-flex">
          <li
            v-for="(cur, index) in courseData"
            :key="index"
            :class="curactive === index ? 'head-navbaac' : 'head-navba'"
            @click="switCourse(cur, index)"
          >
            {{ cur.name }}
          </li>
        </ul>
      </div>
      <div class="nav-list-r dis-flex">
        <div @click="clickHead" class="head">
          <img :src="headimg" alt="" />
          <!-------------退出按钮------------>
          <div v-if="exitBtn" @click="clickEcit" class="exit dis-flex">
            <div class="exit-icon">
              <img src="../../assets/images/exit.png" alt="" />
            </div>
            <div>退出登录</div>
          </div>
        </div>
        <div class="head-name">{{ usernames }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navtop",
  props: ["navtop"],
  data() {
    return {
      // 退出按钮
      exitBtn: false,
      headimg: "",
      logo: "",
      usernames: "",
      casenumber: "",
      courseData: [
        { id: "0", name: "课程管理" },
        { id: "1", name: "班级管理" },
        { id: "2", name: "成长档案管理" },

        // {id: '2', name: '作品管理'},
        // {id: '3', name: '相册管理'},
      ],
      curactive: 0,
    };
  },
  created() {
    console.log(this.$route.path);
    if (this.$route.path == "/classBa") {
      this.curactive = 1;
    } else if (this.$route.path == "/classcheck") {
      this.curactive = 1;
    } else if (this.$route.path == "/classDetail") {
      this.curactive = 1;
    } else if (this.$route.path == "/tolead") {
      this.curactive = 1;
    } else if (this.$route.path == "/index") {
      this.curactive = 0;
    } else if (this.$route.path == "/course") {
      this.curactive = 2;
    } else if (this.$route.path == "/works") {
      this.curactive = 2;
    } else if (this.$route.path == "/jobdetail") {
      this.curactive = 2;
    } else if (this.$route.path == "/upworks") {
      this.curactive = 2;
    } else if (this.$route.path == "/photoList") {
      this.curactive = 2;
    } else if (this.$route.path == "/photodetali") {
      this.curactive = 2;
    } else if (this.$route.path == "/upphoto") {
      this.curactive = 2;
    }
    this.headimg = localStorage.getItem("headimg");
    this.logo = localStorage.getItem("logo");
    this.usernames = localStorage.getItem("usernames");
    this.casenumber = localStorage.getItem("casenumber");
  },
  methods: {
    // navba切换
    switCourse(val, index) {
      console.log(val.name);

      // this.courseData_id = id
      // this.cur_date = id
      this.curactive = index;
      if (val.id === "0" && this.$route.path != "/index") {
        this.$router.push("/index");
      } else if (val.id === "1" && this.$route.path != "/classBa") {
        this.$router.push({
          path: "/classBa",
        });
      } else if (val.id === "2" && this.$route.path != "/course") {
        this.$router.push({
          path: "/course",
        });
      } else if (val.id === "3" && this.$route.path != "/photoList") {
        this.$router.push({
          path: "/photoList",
        });
      }
    },
    // 返回首页
    toIndex() {
      // console.log(this.$route.path)
      if (this.$route.path === "/index") {
        this.$router.go(0);
      } else {
        this.$router.push("/index");
      }
    },
    // 点击头像
    clickHead() {
      this.exitBtn = !this.exitBtn ? true : false;
    },
    // 退出登录
    clickEcit() {
      console.log(111111);
      console.log(111111);
      this.exitBtn = false;
      localStorage.removeItem("username");

      localStorage.removeItem("password");
      localStorage.removeItem("headimg");
      localStorage.removeItem("logo");
      localStorage.removeItem("usernames");
      localStorage.removeItem("casenumber");
      this.$router.push("/");
      
    },
  },
};
</script>

<style scoped>
/*顶部导航*/
.layout-nav {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #ffffff;
}

.nav-list {
  width: 80%;
  height: 60px;
}

.nav-list-l {
  width: 586px;
  /*height: 24px;*/
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  color: #6094ff;
}

.nav-list-ls {
  width: 86px;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  /*margin-top: 5px;*/
  color: #6094ff;
}

.nav-list-ls > img {
  width: 86px;
  height: 24px;
  margin-top: 16px;
}

.head-lists {
}

.head-navba {
  width: 84px;
  height: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #111111;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
}

.head-navbaac {
  width: 84px;
  height: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #ff7800;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
}

.head {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 10px 10px 0 0;
  position: relative;
  cursor: pointer;
}

.head > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.head-name {
  min-width: 42px;
  height: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.exit {
  position: absolute;
  left: -8px;
  top: 50px;
  width: 103px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 21px 3px rgba(4, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
  z-index: 9999;
}

.exit-icon > img {
  margin: 12px 5px 0 10px;
}
</style>