<template>
  <!-------------底部------------>
  <div class="footerby clear">
    <div class="footer-center ">

      <div class="footer-list dis-flex ">
        <div class="footer-l">
          <div class="footer-login">
            <img src="../../assets/images/login.png" alt="">
          </div>
          <ul class="footer-l-o dis-flex">
            <li class="footer-name" @click="about">
              关于我们
            </li>
            <li class="footer-o-y"></li>
            <li class="footer-name"  @click="relation">
              联系我们
            </li>
            <li class="footer-o-y"></li>
            <li class="footer-name " @click="help">
              帮助中心
            </li>
            <li class="footer-o-y"></li>
            <li class="footer-name" @click="service">
              在线客服
            </li>
          </ul>

          <div class="footer-l-t">

          </div>
        </div>
        <div class="footer-records">
          <img  src="@/assets/images/beianbgs.png" alt="">
          <span>{{casenumber}}</span>
        </div>
        <div class="footer-r dis-flex" >
          <div class="footer-r-list margin-l-20">
            <div class="footer-r-list-img">
              <img src="@/assets/images/applet.png" alt="">
            </div>
            <p class="footer-r-list-name">扫码关注小程序</p>
          </div>
          <div class="footer-r-list margin-l-20">
            <div class="footer-r-list-img">
              <img src="@/assets/images/qr-code.png" alt="">
            </div>
            <p class="footer-r-list-name">扫码关注公众号</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import api from '@/service/api/api'
export default {
  name: "underneath",
  props:['casenumber'],
  data() {
    return {

    }
  },
  created() {
  },
  methods:{
    about() {
      this.$router.push('/about')

    },
    relation() {
      this.$router.push('/relation')
    },
    help() {
      this.$router.push('/help')
    },
    service() {
      this.$router.push('/service')
    },

  }
}
</script>

<style scoped>
/*底部*/
.footerby {
  width: 100%;
  /*min-height: 55px;*/
  margin-top: 30px;
  background: #E1E3EA;
}

.footer-center {
  width: 80%;
margin: 10px 30px;
}
.footer-login {
  width: 86px;
  height: 24px;
  font-size: 30px;
  font-weight: bold;
  color: #6094FF;
  margin-bottom: 10px;
}
.footer-login>img {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.footer-list {
  width: 100%;
  /*min-height: 100px;*/
  justify-content: space-around;
}
.footer-l {
  /*width: 500px;*/
}

.footer-l-o {
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.footer-name {
  min-width: 60px;
  height: 50px;
  color: #333333;
  font-size: 14px;
  cursor: pointer;
}
.footer-o-y {
  width: 1px;
  height: 20px;
  border: 1px solid #E1E3EA;
  margin: 16px 10px;
}
.footer-r {
  width: 230px;
}
.footer-r-list{
  min-width: 94px;

}
.footer-r-list-img {
  width: 44px;
  height: 40px;
}
.footer-r-list-img>img {
  width: 44px;
  height: 100%;
  margin-left: 25px;
  margin-top: 5px;
}
.footer-records {
  width: 700px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-top: 35px;
  /*background-color: #4B85FF;*/
}
.footer-records>img {
  margin:-2px 10px;
}
.footer-r-list-name {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #333333;
  margin-top: 10px;
}
</style>